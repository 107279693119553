import { CSSProperties } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import Image from 'next/image';
import { useRouter } from 'next/router';

import { useAuth } from '@zep/auth';
import { CreateSpaceFunnel } from '@zep/web-components';

import S from './LandingMyMetaverse.module.scss';

export const LandingMyMetaverse = (props: LandingMyMetaverseProps) => {
  const { title, list, onItemLinkClick } = props;
  const { user } = useAuth();
  const router = useRouter();
  const createSpaceFunnel = useModal(CreateSpaceFunnel);

  // 지금 시작하기
  const handleClickCreateSpace = async () => {
    if (!user) {
      router.push('/accounts/signin');
      return;
    }

    await createSpaceFunnel.show({ from: 'home' });
  };

  return (
    <section className={S.create}>
      <h2 className={S.title} data-motion="scale-in">
        {title}
      </h2>

      {list && (
        <div className={S.card_wrapper} data-motion="stagger-in">
          {list?.map((item, index) => (
            <div
              key={index}
              className={S.card}
              data-motion-item
              style={{ '--motion-delay': `${0.1 * index}s` } as CSSProperties}>
              <div className={S.text_wrapper}>
                <div className={S.text_items}>
                  <h3
                    className={S.sub_title}
                    dangerouslySetInnerHTML={{ __html: item.title }}
                  />
                  <p className={S.card_description}>{item.description}</p>
                </div>
                {item.url ? (
                  <a
                    href={item.url}
                    className={S.link}
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => {
                      onItemLinkClick?.(item);
                    }}>
                    {item.linkName}
                    <Image
                      src="/images/light/landing/arrow-right-primary.svg"
                      width="24"
                      height="24"
                      alt="create now"
                    />
                  </a>
                ) : (
                  <button
                    type="button"
                    className={S.link}
                    onClick={() => {
                      onItemLinkClick?.(item);
                      handleClickCreateSpace();
                    }}>
                    {item.linkName}
                    <Image
                      src="/images/light/landing/arrow-right-primary.svg"
                      width="24"
                      height="24"
                      alt="create now"
                    />
                  </button>
                )}
              </div>
              <div className={S.image_wrapper}>
                <Image
                  className={S.card_image}
                  src={item.desktopImageSrc}
                  width="570"
                  height="300"
                  alt={item.imageAlt ?? ''}
                  loading="lazy"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </section>
  );
};

type LandingMyMetaverseItem = {
  id: number;
  title: string;
  description: string;
  linkName: string;

  /**
   * url 유무에 따라 다이얼로그 표시 혹은 하이퍼텍스트 링크로 연결
   */
  url?: string;

  desktopImageSrc: string;
  imageAlt: string;
};

type LandingMyMetaverseProps = {
  title: string;
  list: LandingMyMetaverseItem[];
  onItemLinkClick?: (item: LandingMyMetaverseItem) => void;
};
