import { useEffect, useMemo, useRef, useState } from 'react';
import { inView } from 'motion';
import { useRouter } from 'next/router';

import { useViewportDevice } from '@zep/react';
import { Button } from '@zep/ui';

import { joinUtmQueryParams } from '../../../../utils';
import { HorizontalTicker } from '../../shared';

import S from './LandingUsedClient.module.scss';

export const LandingUsedClient = (props: LandingUsedClientProps) => {
  const { title, description, logos, buttonText, buttonLink, onButtonClick } =
    props;
  const [stop, setStop] = useState(false);
  const sectionRef = useRef<HTMLElement>(null);
  const router = useRouter();
  const { isMobile } = useViewportDevice();

  const logoImagesByGroup = useMemo(() => {
    if (!logos) return {};
    return logos?.reduce<Record<string, ClientLogoImageType[]>>((acc, cur) => {
      const { groupById } = cur;
      if (acc[groupById]) acc[groupById].push(cur);
      else acc[groupById] = [cur];
      return acc;
    }, {});
  }, [logos]);

  useEffect(() => {
    setStop(true);
  }, []);

  useEffect(() => {
    if (!sectionRef.current) return;
    inView(sectionRef?.current, () => {
      setStop(false);
      return () => setStop(true);
    });
  }, []);
  const isInternal = buttonLink?.startsWith('/');

  return (
    <section ref={sectionRef} className={S.section}>
      <div className={S.inner}>
        <div className={S.text_box} data-motion="scale-in">
          {title && <h2 className={S.title}>{title}</h2>}
          <p className={S.text}>{description}</p>
        </div>
        {logoImagesByGroup && (
          <div className={S.logos}>
            {Object.values(logoImagesByGroup).map((ticker, index) => (
              <HorizontalTicker
                key={index}
                images={Array(4).fill(ticker).flat()}
                imageClassName={S.logo_image}
                direction={index % 2 == 0 ? 'right' : 'left'}
                stop={stop}
              />
            ))}
          </div>
        )}
        {buttonText && buttonLink && (
          <div className={S.button_area}>
            <Button
              as="a"
              size={isMobile ? 'md' : 'lg'}
              className={S.button}
              href={joinUtmQueryParams(buttonLink, router)}
              target={isInternal ? '_self' : '_blank'}
              rounded
              onClick={onButtonClick}>
              {buttonText}
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

type ClientLogoImageType = {
  imageSrc: string;
  imageAlt: string;
  groupById: number;
};

type LandingUsedClientProps = {
  title: string;
  description: string;
  logos: ClientLogoImageType[];
  buttonText: string;
  buttonLink: string;
  onButtonClick?: () => void;
};
