import { CSSProperties } from 'react';
import Image from 'next/image';
import Link from 'next/link';

import { event, zepAnalytics } from '@zep/analytics';
import { useViewportDevice } from '@zep/react';
import { Button } from '@zep/ui';

import S from './LandingCases.module.scss';

export const LandingCases = (props: LandingCasesProps) => {
  const { title, list, buttonText, buttonLink } = props;
  const { isMobile } = useViewportDevice();
  const isInternal = buttonLink?.startsWith('/');

  return (
    <section className={S.overview_01}>
      <div data-motion="scale-in">
        <div className={S.question_mark}>?</div>
        <h2 className={S.title}>{title}</h2>
      </div>

      {list && (
        <div className={S.card_wrapper} data-motion="stagger-in">
          {list.map((item, index) => (
            <div
              key={index}
              className={S.card}
              data-motion-item
              style={{ '--motion-delay': `${0.1 * index}s` } as CSSProperties}>
              <div className={S.text_wrapper}>
                <h3>{item.title}</h3>
                <p>{item.description}</p>
              </div>
              <Image
                src={item.desktopImageSrc}
                width="560"
                height="340"
                alt={item.imageAlt}
                loading="lazy"
              />
            </div>
          ))}
        </div>
      )}

      {buttonText && buttonLink && (
        <Link href={buttonLink} legacyBehavior passHref prefetch={false}>
          <Button
            as="a"
            size={isMobile ? 'md' : 'lg'}
            className={S.more_cases_button}
            target={isInternal ? '_self' : '_blank'}
            onClick={() => {
              event('conversion', {
                send_to: 'AW-11409990767/7V7aCLiWnocZEO_I2sAq',
                event_code: 'landing_cta_cases',
                button_text: buttonText,
                button_link: buttonLink,
              });
              zepAnalytics.track('landing_cta_click', {
                section_name: 'cases',
                button_link: buttonLink,
                button_text: buttonText,
              });
            }}
            rounded>
            {buttonText}
          </Button>
        </Link>
      )}
    </section>
  );
};

type LandingCasesProps = {
  title: string;
  list: {
    title: string;
    description: string;
    desktopImageSrc: string;
    imageAlt: string;
  }[];
  buttonText: string;
  buttonLink: string;
};
