import { fromPairs } from 'lodash-es';

import { isClient } from '../device';

/**
 *  링크 url 을 a tag 로 변경해주는 함수
 * */
export const replaceUrlWithLink = (text: string) => {
  const urlRegex = /(http(s)?:\/\/|(www\.))([a-z0-9\w]+\.*\/*)+[a-z0-9]{2,4}/gi;

  return text.replace(urlRegex, match => {
    const href = match.startsWith('http') ? match : `http://${match}`; // 상대경로일 경우 앞에 "http://"를 붙임
    return `<a href='${href}' target="_blank" style="color: #3398e8;">${match}</a>`;
  });
};

export const getQueryParamByKey = (
  key: string,
  url = isClient() ? window.location.href : '',
): string | undefined => {
  if (!url.includes('?')) return;
  const queryString = url.split('?')[1];
  const queryParams = fromPairs(
    queryString.split('&').map(kv => kv.split('=')),
  );

  return queryParams[key] ? queryParams[key] : undefined;
};

export const getResizedImage = (imageURL?: string, size?: number) => {
  if (
    (!imageURL?.includes('cloudfront') && !imageURL?.includes('cdn-static')) ||
    !size
  ) {
    return imageURL;
  }

  // 아래 주소처럼 리턴되는 경우
  // https://d4oyjxb64cava.cloudfront.net/uploads/market/Yol8qg/title.png?1657580621
  if (imageURL.match(/(png|jpg|webp)\?/g)) {
    return `${imageURL}&w=${size}`;
  }

  return `${imageURL}?w=${size}`;
};
