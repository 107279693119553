import { useMemo } from 'react';
import Color from 'color';

import { Colors } from '@zep/ui-tokens';

const whiteColor = new Color(Colors.White);

export const useColorContrastHelper = (
  backgroundColor: string,
  colorModeOverride?: 'dark' | 'light',
  options?: {
    textColor: string;
  },
) => {
  const { textColor = Colors.Gray700 } = options ?? {};
  const _textColor = useMemo(() => new Color(textColor), [textColor]);

  return useMemo(() => {
    const color = new Color(backgroundColor);
    const isDarkBackground = colorModeOverride
      ? colorModeOverride === 'dark'
      : color.contrast(_textColor) < color.contrast(whiteColor);
    return {
      isDarkBackground,
      foregroundColor: isDarkBackground ? Colors.White : Colors.Gray700,
      transparentColor: color.alpha(0).string(),
    };
  }, [_textColor, backgroundColor, colorModeOverride]);
};
