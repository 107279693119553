import { useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { animate, inView } from 'motion';
import Image from 'next/image';

import { Breakpoints } from '@zep/ui-tokens';

import S from './LandingTraffic.module.scss';

const isFloat = (n: number) => Number(n) === n && n % 1 !== 0;

export const LandingTraffic = (props: LandingTrafficProps) => {
  const { title, stats } = props;
  const isMobile = useMediaQuery({
    query: `(max-width: ${Breakpoints.Mobile}px)`,
  });

  useEffect(() => {
    const counters = document.querySelectorAll<HTMLSpanElement>(
      '[data-animate-count]',
    );
    if (!stats) return;
    inView(
      '[data-animate-count]',
      () => {
        animate(
          progress =>
            counters.forEach(counter => {
              const count = Number(counter.dataset.animateCount);
              const toFixed = isFloat(count) ? 2 : 0;
              counter.innerHTML = (progress * count)
                .toFixed(toFixed)
                .toLocaleString();
            }),
          {
            delay: 0.2,
            duration: 2,
            easing: 'ease-out',
          },
        );
      },
      { amount: 0.3 },
    );
  }, [stats, isMobile]);

  return (
    <section className={S.traffic}>
      <div className={S.text_wrapper} data-motion="scale-in">
        <div className={S.users_icon}>
          <Image
            src="/images/light/landing/users-primary.svg"
            width="24"
            height="24"
            alt="users icon"
          />
        </div>
        <h2 className={S.title}>{title}</h2>
      </div>
      <div className={S.traffic_columns}>
        {stats?.map((item, index) => (
          <div key={index} className={S.traffic_description}>
            <p>{item.description}</p>
            <div className={S.traffic_columns_text}>
              <span
                className={S.counting}
                data-animate-count={
                  isMobile ? item.mobileTraffic ?? item.traffic : item.traffic
                }>
                0
                <span aria-hidden hidden>
                  {isMobile ? item.mobileTraffic ?? item.traffic : item.traffic}
                </span>
              </span>
              {isMobile ? item.mobileUnit ?? item.unit : item.unit}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

type LandingTrafficProps = {
  title: string;
  stats: {
    description: string;
    traffic: string;
    mobileTraffic: string;
    unit: string;
    mobileUnit: string;
  }[];
};
