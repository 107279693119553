import { useEffect, useMemo } from 'react';
import cns from 'classnames';
import { useKeenSlider } from 'keen-slider/react';
import Image from 'next/image';

import S from './HorizontalTicker.module.scss';

/**
 * @name 좌우 무한 슬라이드
 * @description 이미지가 부모의 너비보다 최소 양옆 2개 이상을 채우고 있어야 합니다.
 */
export const HorizontalTicker = (props: HorizontalTickerProps) => {
  const {
    images,
    direction = 'left',
    stop = false,
    imageClassName,
    duration = 4000,
  } = props;
  const memoDirection = useMemo(
    () => (direction === 'left' ? 1 : -1),
    [direction],
  );
  const animation = useMemo(
    () => ({ duration, easing: (t: number) => t }),
    [duration],
  );
  const [logosRef, slider] = useKeenSlider({
    loop: true,
    renderMode: 'performance',
    drag: false,
    slides: {
      perView: 'auto',
    },
  });

  useEffect(() => {
    slider.current?.on('updated', s => {
      s.moveToIdx(s.track.details.abs + memoDirection, true, animation);
    });

    slider.current?.on('animationEnded', s => {
      s.moveToIdx(s.track.details.abs + memoDirection, true, animation);
    });

    if (images.length <= 0) return;

    if (stop) {
      slider.current?.animator.stop();
    } else {
      slider.current?.update();
    }
  }, [animation, slider, images, memoDirection, stop]);

  return (
    <div ref={logosRef} className="keen-slider">
      {images.map((item, index) => (
        <div className={cns('keen-slider__slide', S.slide)} key={index}>
          <Image
            width={76}
            height={46}
            src={item.imageSrc}
            alt={item.imageAlt ?? ''}
            draggable="false"
            loading="lazy"
            className={cns(S.img, imageClassName)}
          />
        </div>
      ))}
    </div>
  );
};

type HorizontalTickerProps = {
  imageClassName?: string;
  images: { id: number; imageSrc: string; imageAlt: string }[];
  direction?: 'left' | 'right';
  duration?: number;
  stop?: boolean;
};
