import { getResizedImage } from '@zep/utils';

import S from './LandingExperience.module.scss';

export const SpaceCard = (props: SpaceCardProps) => {
  const { title, imageUrl, imageAlt } = props;
  return (
    <div className={S.card}>
      <div className={S.img}>
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img src={getResizedImage(imageUrl, 500)} alt={imageAlt || title} />
      </div>
      <span className={S.text}>{title}</span>
    </div>
  );
};

type SpaceCardProps = {
  title: string;
  imageUrl: string;
  imageAlt: string;
};
