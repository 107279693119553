import Link from 'next/link';
import { useRouter } from 'next/router';

import { event, zepAnalytics } from '@zep/analytics';
import { useIsomorphicViewportDevice } from '@zep/react';
import { Button } from '@zep/ui';

import { joinUtmQueryParams } from '../../../utils';

import S from './LandingAgency.module.scss';

export const LandingAgency = (props: LandingAgencyProps) => {
  const router = useRouter();
  const {
    title,
    description,
    desktopImageSrc,
    tabletImageSrc,
    mobileImageSrc,
    buttonText,
    buttonLink,
    imageAlt,
    isUseMotion = true,
  } = props;
  const { isTablet, isMobile } = useIsomorphicViewportDevice();
  const isInternal = buttonLink?.startsWith('/');

  return (
    <section className={S.partners}>
      <div data-motion={isUseMotion && 'scale-in'}>
        <p className={S.title}>{title}</p>
        <p className={S.sub_description}>{description}</p>
      </div>

      {buttonText && buttonLink && (
        <Link
          href={joinUtmQueryParams(buttonLink, router)}
          legacyBehavior
          passHref
          prefetch={false}>
          <Button
            as="a"
            size={isMobile ? 'md' : 'lg'}
            className={S.partners_button}
            target={isInternal ? '_self' : '_blank'}
            onClick={() => {
              event('conversion', {
                send_to: 'AW-11409990767/7V7aCLiWnocZEO_I2sAq',
                event_Code: 'landing_cta_agency',
                button_text: buttonText,
                button_link: buttonLink,
              });
              zepAnalytics.track('landing_cta_click', {
                section_name: 'agency',
                button_link: buttonLink,
                button_text: buttonText,
              });
            }}>
            {buttonText}
          </Button>
        </Link>
      )}

      {((!isTablet && !isMobile) || tabletImageSrc || mobileImageSrc) && (
        <div className={S.partners_image}>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            src={
              isMobile
                ? mobileImageSrc ?? ''
                : isTablet
                ? tabletImageSrc ?? ''
                : desktopImageSrc ?? ''
            }
            width={isMobile ? 560 : isTablet ? 1022 : 1623}
            height={isMobile ? 254 : isTablet ? 208 : 179}
            alt={imageAlt ?? ''}
            loading="lazy"
          />
        </div>
      )}
    </section>
  );
};

type LandingAgencyProps = {
  title: string;
  description: string;
  buttonText: string;
  buttonLink: string;
  desktopImageSrc: string;
  tabletImageSrc: string;
  mobileImageSrc: string;
  imageAlt: string;
  isUseMotion?: boolean;
};
