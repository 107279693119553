import { CSSProperties, ReactNode } from 'react';
import { Disclosure } from '@headlessui/react';
import cns from 'classnames';

import S from './LandingFAQ.module.scss';

const ArrowIcon = ({ open }: { open: boolean }) => (
  <img
    className={cns(S.rightIcon, { [S.open]: open })}
    width="24"
    height="24"
    src="/images/light/landing/arrow-down.svg"
    alt="arrow down icon"
  />
);

export const Accordion = (props: AccordionProps) => {
  const { id, title, children, open = false, onClick, ...rest } = props;

  const handleClick = () => {
    onClick && onClick();
  };

  return (
    <Disclosure as="div" className={S.accordion} defaultOpen={open} {...rest}>
      {({ open, close }) => (
        <>
          <Disclosure.Button as="div">
            <button type="button" className={S.button} onClick={handleClick}>
              <span className={S.leftIcon}>Q</span>
              <span>{title}</span>
              <ArrowIcon open={open} />
            </button>
          </Disclosure.Button>
          <Disclosure.Panel className={S.panel}>{children}</Disclosure.Panel>
          <button
            type="button"
            data-id={id}
            aria-hidden="true"
            onClick={() => close()}
            className={S.close}>
            Close
          </button>
        </>
      )}
    </Disclosure>
  );
};

type AccordionProps = {
  id: number;
  title: string;
  children: ReactNode;
  open?: boolean;
  style?: CSSProperties;
  onClick?: () => void;
};
