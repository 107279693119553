import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { zepAnalytics } from '@zep/analytics';
import { useViewportDevice } from '@zep/react';
import { Button } from '@zep/ui';

import S from './LandingRecruit.module.scss';

export const LandingRecruit = (props: LandingRecruitProps) => {
  const { description1, description2, buttonTitle } = props;
  const { locale } = useRouter();
  const { isMobile } = useViewportDevice();

  return (
    <>
      {locale === 'ko' && (
        <section className={S.recruit}>
          <div className={S.logos}>
            <Image
              src="/images/light/landing/naverZ.png"
              alt="naverZ logo"
              width={239}
              height={72}
              loading="lazy"
            />
            <Image
              src="/images/light/landing/supercat.png"
              alt="supercat logo"
              width={239}
              height={72}
              loading="lazy"
            />
          </div>
          <p className={S.sub_text}>
            <span>{description1}</span>
            <span>{description2}</span>
          </p>

          <Link
            href="https://careers.zep.us/"
            legacyBehavior
            passHref
            prefetch={false}>
            <Button
              as="a"
              size={isMobile ? 'md' : 'lg'}
              target="_blank"
              rounded
              className={S.recruit_button}
              onClick={() => {
                zepAnalytics.track('landing_cta_click', {
                  section_name: 'recruit',
                  button_link: 'https://careers.zep.us',
                  button_text: buttonTitle,
                });
              }}>
              {buttonTitle}
            </Button>
          </Link>
        </section>
      )}
    </>
  );
};

type LandingRecruitProps = {
  description1: string;
  description2: string;
  buttonTitle: string;
};
