import { GetStaticPaths, GetStaticProps } from 'next';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';

import { withZepetofulContent } from '@zep/next/server';

import { ZepetofulEntries } from '../../consts';
import { SubLandingPageView } from '../../views';

export const ENTRY_ID_MAP = {
  event: ZepetofulEntries.EVENT_PAGE,
  school: ZepetofulEntries.SCHOOL_PAGE,
  hrd: ZepetofulEntries.HRD_PAGE,
  branding: ZepetofulEntries.BRANDING_PAGE,
};

const SubLandingPage = () => {
  const { subLanding } = useRouter().query;

  if (
    typeof subLanding !== 'string' ||
    !ENTRY_ID_MAP[subLanding as keyof typeof ENTRY_ID_MAP]
  ) {
    return null;
  }

  return (
    <SubLandingPageView
      entryId={ENTRY_ID_MAP[subLanding as keyof typeof ENTRY_ID_MAP]}
    />
  );
};

export default SubLandingPage;

interface Params extends ParsedUrlQuery {
  subLanding: keyof typeof ENTRY_ID_MAP;
}

export const getStaticProps = (context => {
  const { subLanding } = context.params as Params;
  if (!ENTRY_ID_MAP[subLanding as keyof typeof ENTRY_ID_MAP]) {
    return {
      notFound: true,
    };
  }
  return withZepetofulContent(
    ENTRY_ID_MAP[subLanding as keyof typeof ENTRY_ID_MAP],
  )(context);
}) satisfies GetStaticProps;

export const getStaticPaths = (() => {
  if (process.env.NEXT_PUBLIC_STAGE !== 'PRD') {
    return {
      paths: [],
      fallback: 'blocking',
    };
  }
  const paths = Object.keys(ENTRY_ID_MAP).map(key => ({
    params: { subLanding: key },
  }));
  return {
    paths,
    fallback: false,
  };
}) satisfies GetStaticPaths;
