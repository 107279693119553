import React, { useCallback, useMemo, useState } from 'react';
import { useDebounce } from '@toss/react';
import cns from 'classnames';
import { endOfDay, isAfter, isBefore, startOfDay } from 'date-fns';
import { shuffle } from 'lodash-es';
import hash from 'object-hash';

import { useZepetofulContents } from '@zep/apis';
import { ChevronLeftIcon, ChevronRightIcon } from '@zep/icons';
import { Carousel, useCarousel } from '@zep/web-components';

import { ZepetofulEntries } from '../../../../consts';

import { Banner } from './Banner';
import { BannerSchema, BannerSchemaType } from './BannerSchema';
import S from './BannerSlider.module.scss';

const DUAL_BANNERS_BREAKPOINT = 600;

const getVisibleSlidesCount = () => {
  const deviceWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
  return deviceWidth >= DUAL_BANNERS_BREAKPOINT ? 2 : 1;
};

export const BannerSlider = (props: BannerSliderProps<BannerSchemaType>) => {
  const { bannerList } = props;
  const [currentSlide, setCurrentSlide] = useState(0);
  const [sliderCreated, setSliderCreated] = useState(false);
  const [visibleSlidesCount, setVisibleSlidesCount] = useState(
    getVisibleSlidesCount,
  );
  const [indicatorText, setIndicatorText] = useState('');
  const [containerRef, sliderRef] = useCarousel(
    {
      initial: 0,
      loop: true,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
        updateIndicatorText();
      },
      created(slider) {
        setSliderCreated(true);
        setCurrentSlide(slider.track.details.rel);
        updateIndicatorText();
      },
      slides(containerWidth: number, elems: HTMLElement[]) {
        const isMobile =
          typeof window !== 'undefined' &&
          window.innerWidth < DUAL_BANNERS_BREAKPOINT;
        const spacing = isMobile ? 16 : 20;
        const visibleSlidesWidth = Math.min(
          isMobile ? 340 : 1140,
          isMobile ? containerWidth - 64 : containerWidth * 0.9,
        );
        const _visibleSlidesCount = getVisibleSlidesCount();
        setVisibleSlidesCount(_visibleSlidesCount);
        const spacingInVisibleSlides = spacing * (_visibleSlidesCount - 1);
        const slideWidth =
          (visibleSlidesWidth - spacingInVisibleSlides) / _visibleSlidesCount;
        const origin = (containerWidth - visibleSlidesWidth) / 2;
        const config = {
          origin: origin / containerWidth,
          size: slideWidth / containerWidth,
          spacing: spacing / containerWidth,
        };
        return new Array(elems.length).fill(config);
      },
    },
    [Carousel.AutoSlidePlugin()],
  );

  const updateIndicatorText = useDebounce(() => {
    setIndicatorText(`${currentSlide + 1}/${safeBannerList.length}`);
  }, 150);

  const add = useCallback(
    (amount: number) => {
      const slider = sliderRef.current;
      if (!slider) {
        return;
      }
      slider.moveToIdx(slider.track.details.abs + amount, true);
    },
    [sliderRef],
  );

  const focus = useCallback(
    (index: number) => {
      const slider = sliderRef.current;
      if (!slider) {
        return;
      }
      const slidesLength = slider.slides.length;
      let distance = index - slider.track.details.rel;
      if (distance > slidesLength / 2) {
        distance -= slidesLength;
      } else if (distance < -slidesLength / 2) {
        distance += slidesLength;
      }
      if (distance >= visibleSlidesCount) {
        distance -= visibleSlidesCount - 1;
      }
      slider.moveToIdx(slider.track.details.abs + distance, true);
    },
    [sliderRef, visibleSlidesCount],
  );

  const visibleSlideIndexes = useMemo(() => {
    const slider = sliderRef.current;
    if (!slider || !sliderCreated) {
      return new Set();
    }
    const indexes = new Set<number>([currentSlide]);
    for (let i = 0; i < visibleSlidesCount; i++) {
      indexes.add(slider.track.absToRel(slider.track.details.abs + i));
    }
    return indexes;
  }, [sliderRef, sliderCreated, currentSlide, visibleSlidesCount]);

  const safeBannerList = useMemo(() => {
    return bannerList.reduce((acc, banner) => {
      try {
        const parsed = BannerSchema.parse(banner);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        acc.push(parsed);
        return acc;
      } catch (e) {
        console.error(e);
        return acc;
      }
    }, []);
  }, [bannerList]);

  return (
    <section className={S.slider_wrapper}>
      {!!safeBannerList?.length && (
        <Carousel
          ref={containerRef}
          className={cns({ [S.ready]: sliderCreated })}>
          {safeBannerList.map((banner, index) => (
            <Banner
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              key={banner.id}
              banner={banner}
              isVisible={visibleSlideIndexes.has(index)}
              onRequestFocus={() => focus(index)}
            />
          ))}
        </Carousel>
      )}
      {safeBannerList?.length && sliderCreated && (
        <div className={S.controller}>
          <div className={S.controller_button}>
            <ChevronLeftIcon onClick={() => add(-2)} />
          </div>
          <div className={S.controller_indicator}>
            <span className={S.controller_indicator_text}>{indicatorText}</span>
          </div>
          <div className={S.controller_button}>
            <ChevronRightIcon onClick={() => add(2)} />
          </div>
        </div>
      )}
    </section>
  );
};

export const Banners = () => {
  const { data: banners } = useZepetofulContents<BannerSchemaType[]>(
    ZepetofulEntries.BANNERS,
    {
      refetchOnWindowFocus: false,
    },
  );

  const activeBanners = useMemo(() => {
    if (!banners) {
      return [];
    }
    const today = new Date();
    return shuffle(
      banners.filter(
        b =>
          (b.start_date
            ? isAfter(today, startOfDay(new Date(b.start_date)))
            : true) &&
          (b.end_date ? isBefore(today, endOfDay(new Date(b.end_date))) : true),
      ),
    );
  }, [banners]);

  return <BannerSlider bannerList={activeBanners} key={hash(activeBanners)} />;
};

type BannerSliderProps<T> = {
  bannerList: T[];
};
