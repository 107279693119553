import Link from 'next/link';
import { useRouter } from 'next/router';

import S from './LandingGDPR.module.scss';

export const LandingGDPR = (props: LandingGDPRProps) => {
  const { locale = 'ko' } = useRouter();
  const { title, description, privacyPolicyLabel } = props.data;

  const renderDescription = () => {
    const parts = description.split('{{privacy_policy}}');
    if (parts.length < 2) return description;
    return (
      <>
        {parts[0]}
        <Link
          className={S.policy_link}
          href={`https://docs.channel.io/zep-support/${locale}/articles/8232ed3d`}
          target="_blank"
          rel="noopener noreferrer"
          prefetch={false}>
          {privacyPolicyLabel}
        </Link>

        {parts[1]}
      </>
    );
  };

  return (
    <div className={S.section}>
      <div className={S.container}>
        <GDPRLogo />
        <div className={S.wrapper}>
          <div className={S.title}>{title}</div>
          <div className={S.description}>{renderDescription()}</div>
        </div>
      </div>
    </div>
  );
};

export const GDPRLogo = () => {
  return (
    <div className={S.logo_wrapper}>
      <div style={{ position: 'relative', width: '100%', height: '100%' }}>
        <img src="/images/landing/gdpr_logo.png" alt="gdpr logo" />
      </div>
    </div>
  );
};

interface LandingGDPRProps {
  data: {
    title: string;
    description: string;
    privacyPolicyLabel: string;
  };
}
