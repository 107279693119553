import { DependencyList, useEffect, useState } from 'react';
import { scroll } from 'motion';

/**
 * [data-motion] 커스텀 속성을 가진 요소들에게 애니메이션 적용
 * @description 스크롤 이벤트를 통해 요소가 화면에 보일 때 애니메이션이 실행됩니다.
 */
export const useMotion = (deps: DependencyList = []) => {
  const intersectionRatio = 1 - 0.2;
  const [motionElements, setMotionElements] = useState<NodeListOf<Element>>();

  useEffect(() => {
    const elements = document.querySelectorAll('[data-motion]');
    setMotionElements(elements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  useEffect(() => {
    const stop = scroll(
      info => {
        motionElements?.forEach(el => {
          const top =
            info.y.containerLength * intersectionRatio + info.y.current;
          if (top >= (el as HTMLElement).offsetTop) {
            el.classList.add('motion-animate');
          }
        });
      },
      { axis: 'y' },
    );
    return () => stop();
  }, [intersectionRatio, motionElements]);

  return { motionElements };
};
