import { SVGProps, useEffect, useMemo, useRef, useState } from 'react';
import { useInterval } from '@toss/react';
import { useKeenSlider } from 'keen-slider/react';
import { inView } from 'motion';
import Link from 'next/link';
import { useRouter } from 'next/router';

import 'keen-slider/keen-slider.min.css';

import { zepAnalytics } from '@zep/analytics';
import { useIsomorphicViewportDevice } from '@zep/react';
import { Button } from '@zep/ui';

import { ENTRY_ID_MAP } from '../../../../pages/home/[subLanding]';
import { Banners } from '../../common';

import S from './LandingExperience.module.scss';
import { SpaceCard } from './SpaceCard';

export const LandingExperience = (props: LandingExperienceProps) => {
  const { title, spaces, buttonText, buttonLink } = props;
  const { query } = useRouter();
  const sectionRef = useRef<HTMLElement>(null);
  const [isPaused, setIsPaused] = useState<boolean>(false);
  const [selectedCategoryIndex, setSelectedCategoryIndex] = useState<number>(0);
  const [slideLoaded, setSlideLoaded] = useState<boolean>(false);
  const [currentSpaceIndex, setCurrentSpaceIndex] = useState<number>(0);
  const { isMobile } = useIsomorphicViewportDevice();
  const isBannerHidden =
    !!ENTRY_ID_MAP[(query.subLanding ?? '') as keyof typeof ENTRY_ID_MAP];

  const [categorySliderRef, categorySlider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: { perView: 'auto' },
  });

  const [spaceSliderRef, spaceSlider] = useKeenSlider<HTMLDivElement>({
    initial: 0,
    slides: { perView: 2, spacing: 10 },
    breakpoints: {
      '(min-width: 560px)': {
        slides: { perView: 3, spacing: 10 },
      },
      '(min-width: 768px)': {
        slides: { perView: 4, spacing: 10 },
      },
      '(min-width: 1024px)': {
        slides: { perView: 5, spacing: 12 },
      },
    },
    created() {
      setSlideLoaded(true);
    },
  });

  const handleSpaceMove = (category: string, paused: boolean) => {
    const index = visibleSpaces.findIndex(space => space.category === category);
    spaceSlider.current?.moveToIdx(index);
    setIsPaused(paused);
  };

  const handleSpaceNavigate = (navigation: 'prev' | 'next') => {
    if (navigation === 'prev') {
      spaceSlider.current?.prev();
    }

    if (navigation === 'next') {
      spaceSlider.current?.next();
    }

    setIsPaused(true);
  };

  const categories = useMemo(() => {
    if (!spaces) return [];
    const _categories = spaces
      .filter(feature => feature.category)
      .map(feature => feature.category);
    return [...new Set(_categories)] as string[];
  }, [spaces]);

  const visibleSpaces = useMemo(() => {
    if (!spaces) return [];
    return spaces.filter(feature => feature.hidden === false);
  }, [spaces]);

  const lastSpace =
    spaceSlider.current &&
    spaceSlider.current.track.details?.slides.length -
      (spaceSlider.current?.options.slides as any).perView;

  // autoplay
  useInterval(
    () => {
      if (!spaces) return;
      currentSpaceIndex === lastSpace
        ? spaceSlider.current?.moveToIdx(0)
        : spaceSlider.current?.next();
    },
    {
      delay: isPaused ? null : 3000,
    },
  );

  spaceSlider.current?.on('slideChanged', e => {
    if (!spaces) return;
    const index = e.track.details.abs;
    const _selectedCategoryIndex = categories.findIndex(
      category => category === spaces[index].category,
    );
    categorySlider.current?.moveToIdx(_selectedCategoryIndex);
    setSelectedCategoryIndex(_selectedCategoryIndex);
    setCurrentSpaceIndex(spaceSlider.current?.track.details.rel as number);
  });

  // 섹션 진입/이탈 시 autoplay 여부 제어
  useEffect(() => {
    if (!sectionRef?.current) return;

    // cleanup
    inView(sectionRef.current, () => () => setIsPaused(true));
  }, []);

  useEffect(() => {
    if (spaces && spaces?.length <= 0) return;
    categorySlider.current?.update();
    spaceSlider.current?.update();
  }, [categorySlider, spaceSlider, spaces]);

  return (
    <section ref={sectionRef} className={S.section}>
      {!isBannerHidden && (
        <div className={S.banners_wrapper}>
          <Banners />
        </div>
      )}
      <div className={S.inner}>
        <h2 className={S.title} data-motion="scale-in">
          {title}
        </h2>

        {categories.length !== 0 && (
          <div className={S.category}>
            <div ref={categorySliderRef} className="keen-slider">
              {categories?.map((category, index) => (
                <div className="keen-slider__slide" key={index}>
                  <Button
                    variant={
                      selectedCategoryIndex === index ? 'primary' : 'white'
                    }
                    size="sm"
                    rounded
                    bordered
                    onClick={() => handleSpaceMove(category, true)}>
                    {category}
                  </Button>
                </div>
              ))}
            </div>
          </div>
        )}

        <div className={S.spaces}>
          <div className={S.list}>
            {slideLoaded && spaceSlider.current && (
              <button
                type="button"
                className={S.btn}
                onClick={() => handleSpaceNavigate('prev')}
                disabled={currentSpaceIndex === 0}>
                <ArrowRightIcon aria-label="Prev" />
              </button>
            )}
            <div ref={spaceSliderRef} className="keen-slider">
              {visibleSpaces?.map((feature, index) => (
                <div className="keen-slider__slide" key={index}>
                  <Link
                    href={feature.linkUrl}
                    onClick={() => {
                      zepAnalytics.track('landing_featured_click', {
                        title: feature.title,
                        link: feature.linkUrl,
                      });
                    }}
                    prefetch={false}>
                    <SpaceCard
                      title={feature.title}
                      imageUrl={feature.thumbnailUrl}
                      imageAlt={feature.title}
                    />
                  </Link>
                </div>
              ))}
            </div>
            {slideLoaded && (
              <button
                type="button"
                className={S.btn}
                onClick={() => handleSpaceNavigate('next')}
                disabled={currentSpaceIndex === lastSpace}>
                <ArrowRightIcon aria-label="Next" />
              </button>
            )}
          </div>
        </div>

        {buttonText && buttonLink && (
          <div className={S.button_area}>
            <Link href={buttonLink} passHref legacyBehavior prefetch={false}>
              <Button
                as="a"
                size={isMobile ? 'md' : 'lg'}
                target={'_blank'}
                variant="secondary"
                rounded
                className={S.button}
                onClick={() => {
                  zepAnalytics.track('landing_cta_click', {
                    section_name: 'experience',
                    button_link: buttonLink,
                    button_text: buttonText,
                  });
                }}>
                {buttonText}
              </Button>
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

const ArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={17}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M8.187 4.232a.636.636 0 0 1 .9 0l3.819 3.818a.637.637 0 0 1 0 .9l-3.819 3.818a.636.636 0 0 1-.9-.9l2.732-2.732H3.547a.636.636 0 0 1 0-1.272h7.372L8.187 5.132a.636.636 0 0 1 0-.9Z"
      fill="currentColor"
    />
  </svg>
);

type LandingExperienceProps = {
  title: string;
  spaces: {
    category: string;
    title: string;
    hidden: boolean;
    linkUrl: string;
    thumbnailUrl: string;
  }[];
  buttonText: string;
  buttonLink: string;
};
