import Link from 'next/link';
import { useRouter } from 'next/router';

import { useIsomorphicViewportDevice } from '@zep/react';
import { Button } from '@zep/ui';
import { cn } from '@zep/utils';

import { joinUtmQueryParams } from '../../../utils';

import S from './LandingIntro.module.scss';

export const LandingIntro = (props: LandingIntroProps) => {
  const { title, source, buttonText, buttonLink, onButtonClick } = props;
  const isInternal = buttonLink?.startsWith('/');
  const router = useRouter();
  const { isMobile } = useIsomorphicViewportDevice();

  return (
    <section className={S.intro_visual}>
      {source && (
        <video key={source} autoPlay muted playsInline loop className={S.video}>
          <source src={source} />
        </video>
      )}
      <div className={S.background_shadow}>
        <div className={S.title_wrapper} data-motion="scale-in">
          {title && <h2 className={S.intro_title}>{title}</h2>}
          {buttonText && buttonLink && (
            <Link
              href={joinUtmQueryParams(buttonLink, router)}
              passHref
              legacyBehavior
              prefetch={false}>
              <Button
                as="a"
                size={isMobile ? 'sm' : 'lg'}
                className={cn(
                  S.intro_button,
                  isMobile ? 'w-[140px]' : 'w-[220px]',
                )}
                variant="primary"
                onClick={onButtonClick}
                target={isInternal ? '_self' : '_blank'}>
                {buttonText}
              </Button>
            </Link>
          )}
        </div>
      </div>
    </section>
  );
};

type LandingIntroProps = {
  title: string;
  source: string;
  buttonText: string;
  buttonLink: string;
  onButtonClick?: () => void;
};
