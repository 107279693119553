import { CSSProperties } from 'react';

import { useIsomorphicViewportDevice } from '@zep/react';

import S from './LandingWhy.module.scss';

export const LandingWhy = (props: LandingWhyProps) => {
  const { title, description, list } = props;
  const { isTablet, isMobile } = useIsomorphicViewportDevice();

  return (
    <section className={S.overview_02} data-motion="stagger-in">
      <div className={S.card_wrapper}>
        <div className={S.text_card} data-motion="scale-in">
          <div className={S.question_mark}>?</div>
          {title && <h2 className={S.title}>{title}</h2>}
          {description && <p className={S.sub_description}>{description}</p>}
        </div>

        {list?.map((item, index) => (
          <div
            key={index}
            className={S.card}
            data-motion-item
            style={{ '--motion-delay': `${0.1 * index}s` } as CSSProperties}>
            <div className={S.text_wrapper}>
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>

            <div className={S.image_wrapper}>
              <img
                src={
                  isMobile
                    ? item.mobileImageSrc ?? ''
                    : isTablet
                    ? item.tabletImageSrc ?? ''
                    : item.desktopImageSrc
                }
                width="360"
                height="188"
                alt={item.imageAlt ?? ''}
                loading="lazy"
              />
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

type LandingWhyProps = {
  title: string;
  description: string;
  list: {
    title: string;
    description: string;
    desktopImageSrc: string;
    tabletImageSrc: string;
    mobileImageSrc: string;
    imageAlt: string;
  }[];
};
