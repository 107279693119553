import { useEffect, useState } from 'react';
import { useIsomorphicLayoutEffect } from '@toss/react';
import cns from 'classnames';

import { event, zepAnalytics } from '@zep/analytics';
import { useColorContrastHelper, useViewportDevice } from '@zep/react';
import { Carousel } from '@zep/web-components';

import { BannerSchemaType } from './BannerSchema';
import S from './BannerSlider.module.scss';

export const Banner = (props: BannerProps) => {
  const { banner, isVisible, onRequestFocus } = props;
  const {
    id,
    title,
    title_mobile,
    description,
    description_mobile,
    tags,
    backgroundColor,
    mode,
    image,
    url,
  } = banner;
  const { isPortableDevice } = useViewportDevice();
  const [isMobileUi, setMobileUi] = useState(false);

  const onClickBanner = () => {
    if (isVisible) {
      zepAnalytics.track('home_banner_click', { title });
      event(`ad_home_banner_click_${id}`);
      window.open(url, '_blank');
    } else {
      onRequestFocus();
    }
  };

  const { isDarkBackground, transparentColor } = useColorContrastHelper(
    backgroundColor,
    mode,
  );

  useIsomorphicLayoutEffect(() => {
    // FIXME: SSR Hydration 중 문제가 발생해서 state로 한번 감싸서 사용
    setMobileUi(isPortableDevice);
  }, [isPortableDevice]);

  useEffect(() => {
    if (isVisible) {
      event(`ad_home_banner_view`);
      event(`ad_home_banner_view_${id}`);
    }
  }, [id, isVisible]);

  return (
    <Carousel.Slide
      className={cns(S.slide, { [S.visible]: isVisible })}
      data-dark={isDarkBackground}
      onClick={onClickBanner}>
      <div className={S.content_wrapper}>
        <div className={S.content} style={{ backgroundColor }}>
          {!isMobileUi && (
            <div className={S.tags_wrapper}>
              {tags.map((tag, index) => (
                <div key={index} className={S.tag}>
                  {tag}
                </div>
              ))}
            </div>
          )}
          <div>
            <span className={S.title}>
              {isMobileUi ? title_mobile || title : title}
            </span>
            <span className={S.description}>
              {isMobileUi ? description_mobile || description : description}
            </span>
          </div>
        </div>
        <div
          className={S.gradient}
          style={{
            background: `linear-gradient(${
              isMobileUi ? 'to top' : 'to right'
            }, ${backgroundColor} 1px, ${transparentColor})`,
          }}
        />
      </div>
      <div className={S.image} style={{ backgroundImage: `url(${image})` }} />
      <div className={S.border} />
    </Carousel.Slide>
  );
};

type BannerProps = {
  banner: BannerSchemaType;
  isVisible: boolean;
  onRequestFocus: () => void;
};
