import { zepAnalytics } from '@zep/analytics';
import { useIsomorphicViewportDevice } from '@zep/react';
import { cn } from '@zep/utils';

import S from './LandingApps.module.scss';

export const LandingApps = (props: LandingAppsProps) => {
  const { title, description, appLink } = props;
  const { isTablet, isMobile, isPortableDevice } =
    useIsomorphicViewportDevice();

  return (
    <section className={S.apps}>
      <div className={S.apps_info}>
        <div className={S.app_contents}>
          <div data-motion="scale-in" className={S.wrapper}>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              className={cn({ 'self-start': !isPortableDevice })}
              src="/images/light/landing/zep_app_square.png"
              width="60"
              height="60"
              alt="cheeck circle"
            />
            <h2 className={S.title}>{title}</h2>
            <p className={S.sub_descriptionription}>{description}</p>
          </div>
          {appLink && (
            <div className={S.app_store} data-motion="scale-in">
              <a
                href={
                  appLink.ios ??
                  'itms-apps://apps.apple.com/app/zep/id1612428484'
                }
                target="_blank"
                onClick={() => zepAnalytics.track('landing_ios_download')}
                rel="noreferrer">
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src="https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/pCbji3wK8aItvfpDUTg2nxF/mC9uH3wK894e942c8ecd1b15f079ff4a2701eda0149tvfvCckPXhJF/apple.png"
                  width="130"
                  height="38"
                  alt="media"
                  loading="lazy"
                />
              </a>
              <a
                href={
                  appLink.android ??
                  'https://play.google.com/store/apps/details?id=us.zep.mobile&hl=en&gl=US'
                }
                target="_blank"
                onClick={() => zepAnalytics.track('landing_android_download')}
                rel="noreferrer">
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src="https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/f99DM3wK87XtvfpDUT6h8HT/RHU4h3wK8944f0ed7a0a545455ec43dd9fa7ffe293etvfz3d624qfL/goole.png"
                  width="130"
                  height="38"
                  alt="media"
                  loading="lazy"
                />
              </a>
            </div>
          )}
        </div>
        <div className={S.app_images} data-motion="scale-in">
          {isTablet || isMobile ? (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              src="https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/uhVQD3wK890tvffZfWseHxK/Gnw633wK894c1215c6bafdd2b996f472189e0068cdatvffZimrre1b/Downloads_Tablet%2CMobile.png"
              width="522"
              height="351"
              alt="media"
              loading="lazy"
            />
          ) : (
            // eslint-disable-next-line @next/next/no-img-element
            <img
              src="
              https://asset-zepetoful.zepeto.io/lZS2as4ZxDWZ/r7MUE3wK8aGtvffZfV4IODJ/D930u3wK894b14042d58abe572f93fa62a893b00f8ftvffZiot377G/Downloads_Desktop.png"
              width="689"
              height="400"
              alt="media"
              loading="lazy"
            />
          )}
        </div>
      </div>
    </section>
  );
};

type LandingAppsProps = {
  title: string;
  description: string;
  appLink: {
    ios: string;
    android: string;
  };
  desktopImageSrc: string;
  mobileImageSrc: string;
};
