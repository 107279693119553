import { z } from 'zod';

export const BannerSchema = z.object({
  id: z.string(),
  title: z.string(),
  title_mobile: z.string().optional(),
  description: z.string(),
  description_mobile: z.string().optional(),
  tags: z.array(z.string()),
  backgroundColor: z.string().regex(/^#[0-9A-F]{6}$/i),
  mode: z.union([z.literal('dark'), z.literal('light')]).optional(),
  image: z.string().url(),
  url: z.string().url(),
  start_date: z.coerce.date().optional(),
  end_date: z.coerce.date().optional(),
});

export type BannerSchemaType = z.infer<typeof BannerSchema>;
