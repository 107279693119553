/* eslint-disable no-useless-escape */

/**
 * 이메일주소 유효성 검증
 */
export const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/**
 * 휴대폰번호 유효성 검증
 */
export const phoneRegex = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/;

/**
 * 한글이 없는지 검증
 */
export const nonKoreanRegex = /^[^ㄱ-ㅎ|ㅏ-ㅣ|가-힣]+$/;

/**
 * 특수문자가 없는지 검증
 */
export const nonSpecialCharRegex =
  /^[^\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]+$/;

/**
 * 숫자가 있는지 검증
 */
export const onlyNumberRegex = /[\d]/;

/**
 * 숫자와 영대문자가 있는지 검증
 */
export const numberLargeCharRegex = /^[0-9A-Z]*$/;
/**
 * 숫자만 있는지 검증
 */
export const numberRegex = /^[0-9]*$/;
/**
 * html을 포함하는지 검증
 */
export const htmlRegex = /<\/?[a-z][\s\S]*>/;

/**
 * url 검증
 */
export const urlRegex =
  /(((s?ftp|https?):\/\/|(www\.)|(mailto:)?[A-Za-z0-9._%+-]+@)\S*[^\s.;,(){}<>"\u201d\u2019])/i;

export const regexNoSpacesOrSpecialChars = /^[^ \p{P}]*$/u;
