import { CSSProperties, useRef } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { zepAnalytics } from '@zep/analytics';
import { useIsomorphicViewportDevice } from '@zep/react';
import { Button } from '@zep/ui';

import { joinUtmQueryParams, urlRegex } from '../../../../utils';

import { Accordion } from './Accordion';
import S from './LandingFAQ.module.scss';

const ConvertLinkToATag = ({ linkText }: { linkText: string }) => {
  const replacedText = linkText.replace(
    urlRegex,
    `<a href="$&" target="_blank" style="color: #3398e8;">$&</a>`,
  );
  return <div dangerouslySetInnerHTML={{ __html: replacedText }} />;
};

export const LandingFAQ = (props: LandingFAQProps) => {
  const { title, list, buttonText, buttonLink } = props;
  const faqListRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const { isMobile } = useIsomorphicViewportDevice();

  const hideOtherAccordion = (id: number) => {
    if (!faqListRef.current) return;
    const items = Array.from(
      faqListRef.current.querySelectorAll('[data-id]'),
    ).filter(el => el.getAttribute('data-id') !== `${id}`);
    items.forEach(el => (el as HTMLElement).click());
  };

  return (
    <section className={S.section}>
      <div className={S.inner}>
        <h2 className={S.title} data-motion="scale-in">
          {title}
        </h2>

        {list && (
          <div ref={faqListRef} className={S.faq} data-motion="stagger-in">
            {list.map((faq, index) => (
              <Accordion
                id={index}
                title={faq.ask}
                key={index}
                onClick={() => hideOtherAccordion(index)}
                data-motion-item
                style={{ '--motion-delay': `${0.1 * index}s` } as CSSProperties}
                open={index === 0}>
                <ConvertLinkToATag linkText={faq.answer} />
              </Accordion>
            ))}
          </div>
        )}

        {buttonText && buttonLink && (
          <div className={S.button_area}>
            <Link
              href={joinUtmQueryParams(buttonLink, router)}
              legacyBehavior
              passHref
              prefetch={false}>
              <Button
                as="a"
                size={isMobile ? 'md' : 'lg'}
                variant="secondary"
                className={S.button}
                rounded
                target="_blank"
                onClick={() => {
                  if (router.pathname.includes('/landing')) {
                    zepAnalytics.track('landing_cta_click', {
                      section_name: 'faq',
                      button_link: buttonLink,
                      button_text: buttonText,
                    });
                  }
                  if (router.pathname.includes('/office')) {
                    zepAnalytics.track('office_landing_inquiry', {
                      place: 'faq',
                    });
                  }
                }}>
                {buttonText}
              </Button>
            </Link>
          </div>
        )}
      </div>
    </section>
  );
};

type LandingFAQProps = {
  title: string;
  list: {
    ask: string;
    answer: string;
  }[];
  buttonText: string;
  buttonLink: string;
};
